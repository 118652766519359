class BrandTextTemplateDefault extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('TextTemplateDefault before load');
	}

	afterLoad() {
		this.$el = $(this);
		let url = window.location.href
		if (url.indexOf("checkout/cart") !== -1 || url.indexOf("blog/post/arriva-acquisto-multi-category-velo-vuse") !== -1) {
			if (this.$el.hasClass('health-warning')) {
				console.log('L\'elemento ha la classe health-warning');
				this.$el.find('.bat-text p').html('<b>Prodotto contenente nicotina, sostanza che crea un\'elevata dipendenza. Uso sconsigliato ai non fumatori. Per info chiama il numero verde 800554088 dell’Istituto Superiore di Sanità. </b><img src="/content/dam/glo-it/immagini/yap.webp" alt="" width="35" height="35" style="margin-right: 5.0px;">');
			}
		}
		

	}

	beforeUpdate() {
		// console.log('HeadlineTemplate before update');
	}

	afterUpdate() {
		// console.log('HeadlineTemplate after update');
	}

	unload() {
		// console.log('HeadlineTemplate after unload');
	}
}

!customElements.get('bat-text-default') &&
	customElements.define('bat-text-default', BrandTextTemplateDefault);
